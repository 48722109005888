import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import EnhancedTable from './TableMUI'
import { editBatch, getBatchAnalytics } from '../application/redux_actions'
import { Button, CircularProgress, Dialog, TextField } from '@material-ui/core'
import NewBatchCreation from '../IndianRailways/ConductDialog/newBatchCreation'
import DropDown from '../singleAuth/Auth/DropDown'
import SettingsIcon from '@material-ui/icons/Settings';

const BatchDetails = ({ BackButton, setSelected, userId, selected, batchList }) => {

    const user_token = useSelector(state => state.auth?.user_token)
    const app = useSelector(state => state.app)
    const programId = app?.user?.programId;
    const [open, setOpen] = useState(false)
    const [otps, setOtps] = useState(undefined);
    const [batchMeta, setBatchMeta] = useState(undefined);
    const [participants, setParticipants] = useState(undefined);
    const [batchDetails, setBatchDetails] = useState(null);

    const moduleId = Object.keys(app?.moduleDetails || {}).filter(mid => app?.moduleDetails?.[mid]?.programs?.includes(programId)).map(mid => mid)[0]

    useEffect(async () => {
        if (user_token && selected.batchId) {
            await getBatchAnalytics({ user_token, batchId: selected.batchId }).then(data => {
                setOtps(data.assessmentOtps);
                setBatchMeta(data.batchMeta[0].meta);
                setParticipants(data.data);
            })
            await editBatch({ user_token, batchId: selected.batchId }).then(res => {
                setBatchDetails(res)
            })
        }

    }, [user_token, selected.batchId])

    if (!participants || !batchMeta || !otps) {
        return <>
            <div style={{ width: "100vw", height: '100vh', textAlign: 'center' }}>Loading...<br />
                Getting data for Batch  <span style={{ fontSize: '1.2rem', fontWeight: 600 }}>{selected?.['Batch Name'] ? `${selected?.['Batch Name']}` : ''}</span>
            </div>

        </>
    }


    return <>
        <div className='flex flex-col h-100'>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                <div className='flex  fit-content w-100' style={{ marginTop: '6vh', display: "flex", alignItems: "center" }}>
                    <div style={{}}>
                        {BackButton()}
                        <div className='select-batch' stlye={{ display: 'flex', flexDirection: 'column' }}>
                            <DropDown
                                styles={{ fontSize: '1.2rem', fontWeight: 600 }}
                                options={batchList.map(e => e?.['Batch Name'])}
                                label={''}
                                inputProps={{}}
                                value={selected?.['Batch Name'] || ''}
                                onChange={(ev) => {
                                    setOtps(null);
                                    setParticipants(null)
                                    setBatchMeta(null)
                                    let batch = batchList.filter(e => e['Batch Name'] == ev.target.value).map(e => e)[0]
                                    setSelected(batch)
                                }}
                                placeholder={''}
                                disabled={false}
                            />
                        </div>
                    </div>
                    <Button style={{ textTransform: "none", textDecoration: "underline" }} disabled={Object.keys(batchDetails || {}).length == 0} onClick={() => setOpen(true)}> <SettingsIcon style={{ margin: "0px 4px", fontSize: '1.2rem' }} /> Edit Batch Details </Button>
                </div>

                <div className='flex w-auto p-white gap-8 align-center bg-white  fit-content' style={{ backgroundColor: "transparent", padding: "16px 0px" }}>
                    {Object.keys(otps || {}).sort((a, b) => a.split(' ')[1] - b.split(' ')[1], 0).map((key, i) => {
                        let value = otps[key]
                        return <div className='bold-center-text' key={key} style={{ backgroundColor: "white", borderRadius: 8, minWidth: '15vw', padding: '1rem' }}>
                            <div className='smaall-text' style={{ textAlign: "left", fontWeight: 700, color: 'black', fontSize: '1rem', lineHeight: "1.5rem" }}>{key}<br />
                                {/* <span style={{ fontSize: '0.9rem', fontWeight: 400, color: "#666" }}>(Code - {value.otp})</span> */}
                            </div>
                            <div className='large-text' style={{ textAlign: "left", fontWeight: 600, marginTop: "2vh" }}>{value.students}</div>
                        </div>
                        return <div key={value.otp} style={{ display: "flex", flexDirection: "column", gap: '1rem' }}>
                            {/* <div className='font-medium text-center' style={{ lineHeight: '1.4rem' }}>{key}<br />(Code - {value.otp})</div> */}
                            <div className='bg-gray br-10 font-bold text-center p-32-4'>{value.students}</div>
                        </div>
                    })}

                </div>
                <EnhancedTable styles={{}} tableTitle={'Your Participant(s)'} defaultRows={25} list={participants} handleBatchClick={() => { }} tableType={"batchAnalytics"} />
            </div>
        </div >
        <Dialog
            aria-labelledby="info-dialog-title"
            open={open}
            maxWidth={false}
            classes={{}}
            className='ir-tisa-font'
        >
            <NewBatchCreation
                _editbatchDetails={batchDetails?.batch}
                coTrainerId={batchDetails?.batch?.coachId?.[1] || batchDetails?.batch?.coachId?.[0]}
                __latestBatch={selected.batchId}
                batchEdit={true}
                app={app}
                firstModule={true}
                coach_id={userId}
                handleCloseInfo={(res) => {
                    if (res) {
                        setBatchDetails(res)
                        setBatchMeta(res.meta)
                    }
                    setOpen(false);
                }}
                moduleId={moduleId}
                user_token={user_token}
            />

        </Dialog >
    </>
}

export default BatchDetails;