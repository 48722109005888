import React, { useEffect, useState } from 'react'
import DropDown from "./DropDown"
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core'

export default ({ field, enableRegister = null, checkFields = false, _value = null, onChange, forBatch = null, batch = null }) => {
    let { metaKey, _schema, data } = field || {}
    let [val, setVals] = useState(null)
    let [conditionVal, setConditionalVal] = useState({})
    useEffect(() => {
        if (data && _schema && data.length > 0 && _schema.length > 0 && !val) {
            let a = getDataObject1(data)
            setVals(a)
        }
    }, [data])

    const getDataObject1 = (data) => {
        let obj = {}
        if (data) {
            const processValues = (values) => {
                const nestedObj = {};
                (values || []).forEach((item) => {
                    if (item.key === "values" && Array.isArray(item.values)) {
                        nestedObj[item.value] = processValues(item.values);
                    } else {
                        nestedObj[item.value] = {};
                        if (item.key === "values") {
                            nestedObj[item.value] = processValues(item.values);
                        } else {
                            nestedObj[item.value] = processValues(item.values);
                        }
                    }
                });

                return nestedObj;
            };

            data.forEach((item) => {
                obj[item.value] = processValues(item.values);
            });
            return obj;
        };
    };

    const findValue = (nestedObj, searchKey) => {
        for (let key in nestedObj) {
            if (key === searchKey) {
                return nestedObj[key];
            }
            if (typeof nestedObj[key] === 'object') {
                const result = findValue(nestedObj[key], searchKey);
                if (result !== undefined) {
                    return result;
                }
            }
        }
        return undefined;
    };

    return <>
        {Object.keys(val || {}).length > 0 && _schema.map((fd, i) => {
            let key = fd.key
            let label = fd.fieldName
            let value = _value || conditionVal?.[key];
            let chk = checkFields ? value ? true : false : true;
            let options = i === 0 ? Object.keys(val) : Object.keys(findValue(val, Object.values(conditionVal || {})[i - 1]) || [])
            return <FormControl variant="outlined" error={chk ? false : true} fullWidth style={{
                margin: batch ? '0px' : '10px 0px'
            }}>
                <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
                <Select
                    native
                    label={label}
                    value={value || ''}
                    onChange={(ev) => {
                        setConditionalVal((old) => ({
                            ...old,
                            [key]: ev.target.value

                        }))
                        if (Object.keys(conditionVal).length == _schema.length) {
                            // enableRegister(true)
                        }
                        forBatch(key, ev.target.value);
                        onChange(metaKey, { ...conditionVal, [key]: ev.target.value })
                    }}
                >
                    <option value="" disabled> {''} </option>
                    {options?.length > 0 && options.map((opt) => <option key={opt} value={opt}>{opt}</option>)}
                </Select>
                <FormHelperText>{chk ? "" : `Please Select ${label}`}</FormHelperText>
            </FormControl>
        })}
    </>
}

