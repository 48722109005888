import { Button, Dialog } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import styles from './NavigationAlertPopup.module.css'


export const NavigationAlertPopup = ({ alertConfig, language = "en", open, onClose, parentId }) => {
    const alertType = alertConfig?.type;
    const alertImageUrl = alertConfig?.imgUrl?.[language];
    const alertTitle = alertConfig?.alertTitle?.[language] || alertConfig?.alertTitle?.["en"] || "You are leaving the Engagement!";
    const alertDescription = alertConfig?.alertDescription?.[language] || alertConfig?.alertDescription?.["en"] || "Do you want to proceed?";
    const yesActionBtn = alertConfig?.yesActionBtn?.[language] || alertConfig?.yesActionBtn?.["en"] || "Yes, I've completed";
    const noActionBtn = alertConfig?.noActionBtn?.[language] || alertConfig?.noActionBtn?.["en"] || "No, Take me back";
    const useStyles_2 = makeStyles((theme) => ({
        paper: {
            width: alertType ? '50%' : '40%',
            padding: '2em',
            borderRadius: '24px',
            backgroundColor: alertType ? '#FFF6E9' : 'none'
        },
    }));

    return <Dialog
        open={open}
        keepMounted
        classes={{
            paper: useStyles_2().paper
        }}
        className='ir-tisa-font'
        disableEscapeKeyDown
        container={() => document.getElementById(parentId)}
    >
        {alertType == 'img' ?
            <>
                <div style={{
                    // backgroundImage: `url(${alertImageUrl})`,
                    // backgroundRepeat: "no-repeat",
                    // backgroundSize: "cover",
                    height: "100%", width: "100%"
                }}>
                    <img
                        style={{ width: '100%' }}
                        alt=""
                        src={alertImageUrl}
                        title="" />
                </div>
                <div className='d-flex align-items-center justify-content-space-between' style={{ marginTop: '1em', gap: '10px' }}>
                    <Button className={styles['Img-yes-action-btn']} onClick={() => onClose('no')}>
                        {noActionBtn}
                    </Button>
                    <Button className={styles['Img-no-action-btn']} onClick={() => onClose('yes')} autoFocus>
                        {yesActionBtn}
                    </Button>
                </div>
            </>
            : <>
                <div style={{ fontWeight: 'bold' }}>
                    {alertTitle}
                </div>
                <div style={{ margin: '1em 0', fontSize: '0.8em' }}>
                    {alertDescription}
                </div>
                <div className='d-flex align-items-center justify-content-space-between' style={{ marginTop: '1em', gap: '10px' }}>
                    <Button className={styles['no-action-btn']} onClick={() => onClose('no')} autoFocus>
                        {noActionBtn}
                    </Button>
                    <Button className={styles['yes-action-btn']} onClick={() => onClose('yes')}>
                        {yesActionBtn}
                    </Button>
                </div>
            </>}
    </Dialog>
}